.beer {
    display: grid;
    grid-template-columns: 55% 45%;
    grid-gap: 1rem;
    -ms-grid-columns: 60% 1rem 40%;
    margin: 1.5rem 2.5rem;
    background: #c7a996 url('assets/boxtexture.png') repeat;
    border: 1px solid #1b140d;
    box-shadow: 0.75rem 0.75rem 0 0 #1b140d;
    position: relative;
}

.beer-detailed-view {
    grid-template-columns: 100%;
    -ms-grid-columns: 100%;
}

.beer,
.beer-recipe {
    > ul {
        padding: 0;
    }
}

.beer-recipe ul {
    padding-bottom: 1rem;
}

.beer-visuals {
    grid-column: 1 / -1;
}

.beer-label, .beer-photo {
    margin: .75em 2em .5em;

    img {
        border: 1px solid #1b140d;
        transform-origin: top right;
    }
}

.beer-photo {
    margin-bottom: -.25rem !important;
}

.beer-label-desktop {
    position: relative;
    margin: 0 1rem 0 0;
    background-size: cover;
    background-position: center center;
    box-shadow: inset 1px 0 .5rem rgb(27 20 13 / 75%);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .65;
        background: #c7a996;
        transition: .75s opacity;
    }

    &:hover::before {
        opacity: .35;
    }
}

.beer-info,
.beer-recipe,
.beer-return-link {
    text-align: left;
    font-size: .875rem;
}

.beer-recipe,
.beer-return-link {
    padding-bottom: 1.5rem;
}

.beer-return-link {
    align-self: end;
    text-transform: capitalize;

    li {
        padding: .1rem 0;
        display: inline-block;

        &::after {
            content: '|';
            padding: 0 .5rem;
        }

        &:last-child::after {
            content: '';
        }
    }
}

.beer-meta * {
    display: block;
}

.beer-description {
    white-space: pre-line;
}

.view-on-untappd {
    a {
        color: #383838;
        display: inline-grid;
        height: 2rem;
        font-size: .8rem;
        align-items: center;
        justify-items: center;
        align-content: center;
        text-transform: uppercase;
        transition: .25s background;
        border: 1px solid #1b140d;
        box-shadow: .3rem .3rem 0 0 #1b140d;
        background: rgba(255, 202, 69, .5);
        grid-template-columns: 2rem 1fr;
        margin: 0 0 0 -.27rem;
        padding: .25rem .75rem .25rem .5rem;

        &::before {
            content: '';
            width: 3rem;
            height: 2rem;
            opacity: .5;
            transition: .25s opacity;
            background: url('assets/untappd.svg');
            background-repeat: no-repeat;
        }

        &:hover {
            background: rgba(255, 202, 69, 1);

            &::before {
                opacity: 1;
            }
        }
    }
}

@media screen and (min-width: 48rem) {
    .beer-label, .beer-photo {
        width: 85%;
        justify-self: right;
        align-self: start;
        transform-origin: top right;
    }

    .beer-detailed-view {
        .beer-content {
            display: grid;
            grid-template-columns: 75% 25%;
        }

        .beer-label, .beer-photo {
            width: auto;
            justify-self: center;
            margin: 0;
            grid-column: 1 / -1;

            img {
                border: 0;
                border-bottom: 1px solid #1b140d;
            }
        }

        ul,
        ol {
            grid-column: 1;
        }
    }

    .beer-recipe {
        margin-top: 1rem;
    }

    .view-on-untappd {
        display: inline;
    }
}

@media screen and (max-width: 48rem) {
    .beer {
        grid-template-columns: 100%;
        grid-gap: 2rem;
        margin: 1.5rem;
        padding: 0 1.5rem 1.5rem;
        min-width: 19.25rem;
    }

    .beer-label, .beer-photo {
        max-width: 75vw;
        justify-self: center;
        padding: 0;
        margin: 0;
    }

    .beer-detailed-view .beer-label, .beer-detailed-view .beer-photo {
        max-width: initial;
        max-height: initial;
        margin: 0 -1.5rem;

        img {
            border: 0;
            border-bottom: 1px solid #1b140d;
        }
    }

    .beer-info,
    .beer-recipe {
        padding: 0;
    }

    .beer-detailed-view .view-on-untappd {
        float: none;

        a {
            margin-left: 0;
        }
    }

    .beer-recipe,
    .beer-recipe > li:nth-child(2) {
        margin-top: 1rem;
    }

    .beer-recipe {
        ul, ol {
            padding: 1rem;
        }

        ul {
            padding-bottom: 0;
        }
    }

    .beer-return-link {
        padding: 1rem 0 1.5rem;
    }
}
