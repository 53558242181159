.beer:not(.beer-detailed-view) .beer-stats {
    padding: 0;
}

.beer-stats {
    display: grid;
    grid-gap: .25rem;
    justify-items: center;
    margin: 1rem -.25rem 1rem 1rem;
    grid-template-columns: repeat(4, 1fr);

    li {
        padding: .5rem;

        p {
            margin: 0;
            width: 3.5rem;
            height: 3.5rem;
            cursor: default;
            line-height: 1.4;
            font-size: .85rem;
            display: inline-grid;
            background: #181818;
            align-items: center;
            justify-items: center;
            align-content: center;
            border: 1px solid #1b140d;
            box-shadow: .3rem .3rem 0 0 #1b140d;
            text-shadow: 1px 1px 2px #96725b;
        }

        span {
            display: block;
            font-weight: 600;
            color: #fff;
        }

        abbr {
            cursor: help;
            font-size: .75rem;
            text-decoration: none;
            color: rgba(255, 255, 255, 1);
            border-bottom: 1px dotted #96725b;
        }
    }
}

@media screen and (min-width: 48rem) {
    .beer-detailed-view .beer-stats {
        margin-top: 0.75rem;
        grid-row: 2 / 5;
        grid-column: 2;
        grid-template-columns: 1fr 1fr;
        margin: .75rem 1rem 0 0;
        grid-gap: 1rem 0;
        align-content: start;
    }
}

@media screen and (max-width: 48rem) {
    .beer-stats {
        margin: 1rem 0 0;
        padding: 0;

        li {
            display: inline-block;
            margin-bottom: 1rem;
        }
    }
}

@media screen and (min-width: 48rem) and (max-width: 64rem) {
    .beer-detailed-view .beer-stats {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 64rem) {
    .beer-stats {
        margin-left: 0;
    }
}
