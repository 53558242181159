.beer-tools {
    padding: 0 1rem;
    grid-template-columns: 1fr;

    .beer-tool {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-bottom: 2rem;

        @media screen and (max-width: 48rem) {
            display: block;

            form {
                margin-bottom: 2rem;
            }
        }
    }
}

table {
    tr td:first-child {
        text-align: right;
    }

    input {
        width: 17rem;
    }

    select {
        width: 17.5rem;
    }
}
